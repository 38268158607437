import React from 'react'
import { Route, Routes } from 'react-router-dom'
import About from '../components/AboutPage/About'
import Landing from '../components/LandingPage/Landing'
import Product from '../components/ProductPage/Product'
import MainContact from '../components/ContactPage/MainContact'
import MainTeam from '../components/TeamPage/MainTeam'
import Executives from '../components/Team/Executives'
import Development from '../components/Team/Development'
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy'

const Roots = () => {
  return (
    <Routes>
      <Route path='/' element={<Landing />}/>
      <Route path="/product" element={<Product />}/>
      <Route path="/team" element={<MainTeam />}>
        <Route path="/team/executives" element={<Executives />}/>
        <Route path="/team/development" element={<Development />}/>
        <Route path="/team/design"/>
      </Route>
      <Route path="about" element={<About />}/>
      <Route path='/contact' element={<MainContact />}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
    </Routes>
  )
}

export default Roots