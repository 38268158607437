import React from 'react'
import { Link } from 'react-router-dom'

const Founder = () => {
  return (
    <section className="text-gray-600 body-font">
  <div className="container px-5 pb-24 mx-auto flex flex-col">
    <div className="lg:w-4/6 mx-auto">
      <div className="flex flex-col sm:flex-row mt-10">
        <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div className="flex flex-col items-center text-center justify-center">
            <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">TechApps</h2>
            <div className="w-12 h-1 bg-tertiary-color rounded mt-2 mb-4"></div>
            <p className="dark:text-primary-color">
              “The only constant is change”. 
              We aim to bring positive change to society through our innovative products that help in every day life. 
            </p>
          </div>
        </div>
        <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left dark:text-primary-color">
          <p className="leading-relaxed text-lg mb-4">
            TechApps is a team with extensive experience in Technology, Product Development, Automation, Finance and Business. We bring our knowledge and expertise together into innovative ideas for products and services that help us in everyday life. We make life easier through the use of Mobile Apps and Technology. 
          </p>
          <br />
          <p className="leading-relaxed text-lg mb-4">
            Do you have an innovative idea? Talk to us, we can work on it together and make it happen.
          </p>
          <Link to="/contact" className="text-secondary-color inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default Founder