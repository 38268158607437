import React, { useState, useEffect } from 'react'
import Footer from '../../Layouts/Footer/Footer'
import NavBar from '../../Layouts/Navbar/NavBar'
import Team from '../Team/Team'
import teamConstants from '../../constants/teamConstant/teamConstant.json'

const MainTeam = () => {
    const [teams, setTeams] = useState({})

    useEffect(() => {
        setTeams(teamConstants)
    }, [])
  return (
    <>
        <NavBar />
        <Team teams={teams} setTeams={setTeams}/>
        <Footer />
    </>
  )
}

export default MainTeam