import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

const Team = ({ teams: {
    departments
} }) => {
    const [defaultActive, setDefaultActive] = useState('Executives') 
    const [active, setActive] = useState()
    const handleActive = (e) => {
        setActive(e.target.value)
        setDefaultActive(false)
    }
    let style = "px-4 py-2 text-sm font-medium text-white capitalize md:py-3 dark:text-primary-color dark:hover:text-white hover:bg-secondary-color rounded-xl md:px-12"
    let backgroundStyle = "bg-secondary-color px-4 py-2 text-sm font-medium text-white capitalize md:py-3 dark:text-white dark:hover:text-white hover:bg-secondary-color rounded-xl md:px-12"
    let defaultStyle = "bg-secondary-color px-4 py-2 text-sm font-medium text-white capitalize md:py-3 dark:text-white dark:hover:text-white hover:bg-secondary-color rounded-xl md:px-12"
  return (
    <section>
            <div className="container px-6 py-10 mx-auto">
                <h1 className="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-primary-color">our team</h1>
                
                <p className="max-w-2xl mx-auto my-6 text-center text-gray-500 dark:text-primary-color">
                    Several brains coming together so as to achieve a single goal - to meet your needs.
                <br />
                <br />
                Meet The Team:
                </p>
                <div className="flex items-center justify-center">
                    <div className="flex items-center p-1 border border-blue-600 dark:border-tertiary-color rounded-xl">
                        {
                            departments?.map((department) => (
                                <Link to={department.url}>
                                    <button 
                                        value={department.departmentName}
                                        onClick={handleActive}
                                        className={(department.departmentName===defaultActive && defaultStyle) || active===department.departmentName ? backgroundStyle : style}
                                    >
                                        {department.departmentName}
                                    </button>
                                </Link>
                            ))
                        }
                    </div>
                </div>
                <Outlet />
            </div>
        </section>
  )
}

export default Team