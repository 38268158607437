import React, { useState, useEffect } from 'react';
import teamConstants from '../../constants/teamConstant/teamConstant.json'

export const TeamContext = React.createContext();

export const TeamProvider = ({ children }) => {
    const [teamContexts, setTeamContexts] = useState({})
    useEffect(() => {
        setTeamContexts(teamConstants)
    }, [])
    return (
    <TeamContext.Provider
        value={{
            teamContexts,
            setTeamContexts
        }}
    >
        { children}
    </TeamContext.Provider>
    );
};