import React from 'react'
import { Link } from 'react-router-dom'

import logoWeb from '../../assets/research.svg'
import logoMob from '../../assets/app-development.svg'
import logoTeam from '../../assets/team.svg'

const Services = () => {
  return (
    <section className="text-gray-600 body-font mb-20">
  <div className="container px-5 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">Our Services</h1>
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto dark:text-primary-color">
        Our process is simple, find a problem that can be solved by technology, come up with potential solutions, discuss it within the team and fine tune into a viable solution.
      </p>
      <br />
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto dark:text-primary-color">
        We develop reliable and secure custom-made software products for our customers.
      </p>
      <div className="flex mt-6 justify-center">
        <div className="w-16 h-1 rounded-full bg-tertiary-color inline-flex"></div>
      </div>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100	 text-indigo-500 mb-5 flex-shrink-0">
          <img src={logoMob} alt="" className='w-12'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Mobile App Development</h2>
          <p className="leading-relaxed dark:text-primary-color">
            We specialize in the development of Mobile Apps for all platforms including integration with payment providers for secure cashless transactions.
          </p>
          <Link to="/contact" className="mt-3 text-secondary-color inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100	 text-indigo-500 mb-5 flex-shrink-0">
          <img src={logoWeb} alt="" className='w-12'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Web Development</h2>
          <p className="leading-relaxed dark:text-primary-color">
            We develop Web Applications and online solutions to help your business grow while ensuring that you optimize your operating costs and resources.
          </p>
          <Link to="/contact" className="mt-3 text-secondary-color inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100	 text-indigo-500 mb-5 flex-shrink-0">
          <img src={logoTeam} alt="" className='w-12'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Consultancy Services</h2>
          <p className="leading-relaxed dark:text-primary-color">
            Do you need help with your IT resources? Talk to us now. We help you in any IT solutions ranging from Hardware, Software, Networks and Security.
          </p>
          <Link to="/contact" className="mt-3 text-secondary-color inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default Services