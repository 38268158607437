import React from 'react'
import Footer from '../../Layouts/Footer/Footer'
import NavBar from '../../Layouts/Navbar/NavBar'
import Founder from '../Founder/Founder'
import Services from '../Offer/Services'
// import Vision from '../Vision/Vision'

const About = () => {
  return (
    <>
      <NavBar />
      <Founder />
      {/* <Vision /> */}
      <Services />
      <Footer/>
    </>
  )
}

export default About