export const links = [
  {
    id: 1,
    url: '/',
    text: 'Home'
  },
  {
    id: 2,
    url: '/product',
    text: 'Our Product'
  }, 
  {
    id: 3,
    url: '/team/executives',
    text: 'The Team'
  },
  {
    id: 4,
    url: '/about',
    text: 'About TechApps'
  }
]