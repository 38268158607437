import React from 'react'
import Footer from '../../Layouts/Footer/Footer'
import NavBar from '../../Layouts/Navbar/NavBar'
import CardSlick from '../CardSlick/CardSlick'

const Product = () => {
  return (
    <>
      <NavBar />
      <CardSlick />
      <Footer />
    </>
  )
}

export default Product