import './App.css';
import { TeamProvider } from './hooks/TeamContext/TeamContext';
import Roots from './pages/Roots';

function App() {
  return (
    <>
      <TeamProvider>
        <Roots />
      </TeamProvider>
    </>
  );
}

export default App;
