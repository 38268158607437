import React from 'react'
import Footer from '../../Layouts/Footer/Footer'
import NavBar from '../../Layouts/Navbar/NavBar'
import PrivacyDetails from './PrivacyDetails'

const PrivacyPolicy = () => {
  return (
    <div className='flex flex-col h-screen justify-between'>
        <NavBar />
        <PrivacyDetails />
        <Footer />
    </div>
  )
}

export default PrivacyPolicy