import React, { useState, useEffect } from 'react'
import Carousel from 'react-elastic-carousel'
import Product from '../Product/Product';

import product from '../../constants/ProductConstant/productConstant.json'

const CardSlick = () => {
    const [products, setProducts] = useState([])

    useEffect(() => {
        setProducts(product.product);
    },[])

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 2 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 1}
      ];
  return (
    <div className='carousel' breakPoints={breakPoints}>
        {
            products?.map((product)=> (
                <Product product={product}/>
            ))
        }
    </div>
  )
}

export default CardSlick