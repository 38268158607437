import React from 'react'

const Product = ({ product }) => {
    const { icon1, icon2, icon3, title1, title2, title3, description1, description2, description3} = product
  return (
        <div>
            <div className="mt-12 mb-10 text-center">
                <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4"> Our Product</h1>
                <p className="max-w-2xl mx-auto my-6 text-center text-gray-500 dark:text-primary-color">
                    At TechApps we are constantly evolving and bringing new ideas to fruition. Below are some of our active products 
                </p>
                <div className="flex mt-6 justify-center">
                    <div className="w-16 h-1 rounded-full bg-tertiary-color inline-flex"></div>
                </div>
            </div>
            <div className="sm:flex flex-wrap justify-center text-center gap-8 mb-5">
                <div className="p-4 sm:w-1/2 md:w-1/2 lg:w-1/4 flex flex-col text-center items-center px-4 py-4">
                    <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
                        <img src={icon1} alt="" className='w-12'/>
                    </div>
                    <div className="flex-grow">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-3">{title1}</h2>
                        <p className="leading-relaxed text-base">
                            {description1}
                        </p>
                    </div>
                </div>
                <div className="p-4 sm:w-1/2 md:w-1/2 lg:w-1/4 flex flex-col text-center items-center px-4 py-4">
                    <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
                        <img src={icon2} alt="" className='w-12'/>
                    </div>
                    <div className="flex-grow">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-3">{title2}</h2>
                        <p className="leading-relaxed text-base">
                            {description2}
                        </p>
                    </div>
                </div>
                <div className="p-4 sm:w-1/2 md:w-1/2 lg:w-1/4 flex flex-col text-center items-center px-4 py-4">
                    <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
                        <img src={icon3} alt="" className='w-12'/>
                    </div>
                    <div className="flex-grow">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-3">{title3}</h2>
                        <p className="leading-relaxed text-base">
                            {description3}
                        </p>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Product