import React from 'react'
import Footer from '../../Layouts/Footer/Footer'
import NavBar from '../../Layouts/Navbar/NavBar'
import Contact from '../Contact/Contact'

const MainContact = () => {
  return (
    <>
        <NavBar />
        <Contact />
        <Footer />
    </>
  )
}

export default MainContact