import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import image from "../../assets/TechApps Logo - White High Res.png";

const Footer = () => {
  return (
    <div className="mt-12 px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8 bg-black text-white lg:w-full">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4 lg:max-w-screen-xl lg:ml-auto lg:mr-auto">
        <div className="sm:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <img className="h-16 footerLogo" src={image} alt="logo" />
          </a>
          <div className="lg:max-w-sm">
            <p className="text-sm ">Ideas with big Impact</p>
            <p className="mt-4 text-sm">Sustainable business models</p>
            <p className="mt-4 text-sm">Positive Change to society</p>
          </div>
        </div>
        <div className="space-y-2 text-sm mt-3.5">
          <p className="text-base font-bold tracking-wide">Contacts</p>
          <div className="flex">
            <p className="mr-1">Phone:</p>
            <a
              aria-label="Our phone"
              title="Our phone"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              +230 5250 4299
            </a>
          </div>
          <div className="flex">
            <p className="mr-1">Email:</p>
            <a
              href="mailto:contact@techapps.cloud"
              aria-label="Our email"
              title="Our email"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              contact@techapps.cloud
            </a>
          </div>
          <div className="flex">
            <p className="mr-1">Address:</p>
            <a
              href="https://goo.gl/maps/N8sT2e413X4bE2Gx7"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Our address"
              title="Our address"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Zendō, 5th Floor, ICONEBENE Rue de L'Institut, Ebène, 72201,
              Mauritius
            </a>
          </div>
        </div>
        <div className="mt-3.5">
          <span className="text-base font-bold tracking-wide">Social</span>
          <div className="flex items-center mt-1 space-x-3">
            <a
              href="https://www.linkedin.com/company/techappsltd/"
              className="transition-colors duration-300 hover:text-deep-purple-accent-400"
              target="_blank"
            >
              <FaLinkedin />
            </a>
          </div>
          <p className="mt-4 text-sm">
            Connect with us on Social Media to find out our latest news and
            updates.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row lg:max-w-screen-xl lg:ml-auto lg:mr-auto">
        <p className="text-sm">
          © Copyright 2022 TechApps Ltd. All rights reserved.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <Link
              to="/privacy-policy"
              className="text-sm transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
