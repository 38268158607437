import React from 'react'
import { Link } from 'react-router-dom'
import image from '../../assets/undraw_mello_otq1.svg'

const Hero = () => {
  return (
    <div className="container px-6 py-16 mx-auto">
      <div className="items-center lg:flex">
          <div className="w-full lg:w-1/2">
              <div className="lg:max-w-lg">
                  <h1 className="text-2xl font-semibold text-gray-800 uppercase dark:text-primary-color lg:text-3xl">techApps at your service</h1>
                  <p className="mt-2 text-gray-600 dark:text-primary-color">
                    We create innovative solutions to solve everyday problems and bring positive impact to society
                  </p>
                  <Link to="/product">
                    <button className="w-full px-3 py-2 mt-6 text-xs font-medium text-white uppercase transition-colors duration-200 transform bg-secondary-color rounded-md lg:w-auto hover:bg-primary-color focus:outline-none">View our products</button>
                  </Link>
              </div>
          </div>

          <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
              <img className='w-full h-full lg:max-w-2xl' src={image} alt="heroImage" />
          </div>
      </div>
    </div>
  )
}

export default Hero