import React from 'react'

const PrivacyDetails = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs uppercase rounded-full bg-teal-accent-400 text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-primary-color">
              Our Privacy Policy
            </p>
          </div>
          <p className="mb-2 tracking-tight text-gray-900  md:mx-auto">
            At TechApps we value your privacy and we take all necessary measures to protect your Personal Information. This privacy notice discloses the privacy practices for <span className='text-sky-900	'> https://techapps.cloud.</span> This privacy notice applies solely to information collected by this website. It will notify you of the following:
          </p>
          <br />
          <div className="">
            <ol>
                <li>
                    i. What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.
                </li>
                <li>
                    ii. What choices are available to you regarding the use of your data.
                </li>
                <li>
                    iii. The security procedures in place to protect the misuse of your information.
                </li>
                <li>
                    iv. How you can correct any inaccuracies in the information.
                </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl sm:mx-auto">
        <div className=" gap-16 row-gap-8 lg:grid-cols-2">
          <div className="space-y-8">
            <div>
              <p className="mb-4 text-xl font-medium">
                Collection of non-personal information
              </p>
              <p className="text-gray-700">
                Registration is not required for you to use our website. If you are a visitor, we do not collect any personal information about you, except to the limited extent through the use of Google Analytics tool which provides us website statistics. This tool collects only information such as IP address and Web Browser information and this information do not allow us to personally identify you. You can therefore be assured that we cannot and do not collect any personal information through the use of any analytics tool.
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
                Collection of Personal Information
              </p>
              <p className="text-gray-700">
                We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email, phone or via our online contact forms. We will not sell or rent this information to anyone. We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organisation, other than as necessary to fulfil your request, e.g. to ship an order. Unless you ask us not to, we may contact you via email in the future to tell you about new products or services, or changes to this privacy policy. You may choose to provide us your Personal Information through our Contact Form. In this case, we will contact you to respond to your queries or to develop a business relationship with you.
                <br />
                <br />
                The information that we collect through our contact form is:
                <ol>
                    <li>
                        i. Name and/or Surname
                    </li>
                    <li>
                        ii. Email Address
                    </li>
                    <li>
                        iii. Telephone Number
                    </li>
                    <li>
                        iv. Any other personal information that you wish to provide freely in the form of free text in the message section of our contact form
                    </li>
                </ol>
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
                Your access to and control over information
              </p>
              <p className="text-gray-700">
                You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:
              </p>
              <div className="text-gray-700">
                  <ol>
                      <li>
                          See what data we have about you, if any.
                      </li>
                      <li>
                          Change/correct any data we have about you.
                      </li>
                      <li>
                          Have us delete any data we have about you.
                      </li>
                      <li>
                          Express any concern you have about our use of your data.
                      </li>
                  </ol>
              </div>
            </div>
          
            <div children="">
              <p className="mb-4 text-xl font-medium">
                Data Storage and Security
              </p>
              <p className="text-gray-700">
                We take all precautions to protect your information. When you submit sensitive information via our website, your information is protected both online and offline. All information on our website is transmitted in a secure way using the https protocol. We also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
                <br />
                <br />
                Please note that we do not host any data and we make use of a third-party provider for our website hosting. As such we do not have control on the exact location where our website including your Personal Information is hosted.
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
                Contact Us
              </p>
              <p className="text-gray-700">
                If you feel that we are not abiding by this privacy policy, you should contact us immediately at <span className='text-sky-900	'>contact@techapps.cloud</span> .
              </p>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyDetails