import React, { useContext } from 'react'
import { TeamContext } from '../../hooks/TeamContext/TeamContext'

const Executives = () => {
    const { teamContexts } = useContext(TeamContext)
    const { executives } = teamContexts
  return (
    <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-3">
                    {
                        executives?.map((executive) => (
                            <div className="flex flex-col items-center">
                        <img className="object-cover w-full rounded-xl aspect-square" src={executive.img} alt="" />
                        
                        <h1 className="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-primary-color">{executive.name}</h1>
                        
                        <p className="mt-2 text-gray-500 capitalize dark:text-primary-color">{executive.role}</p>
                    </div>
                        ))
                    }
                </div>
  )
}

export default Executives